import React from "react"
import { graphql } from "gatsby"
import "normalize.css"

import Layout from "components/page/Layout"
import SEO from "components/page/Seo"
import Button from "components/atoms/Button"
import { Title, Paragraph } from "components/atoms/Typography"

import StandardPadding from "components/atoms/StandardPadding"

const NotFoundPage = ({ data }) => {
  const site = data.contentfulSite
  return (
    <Layout site={site}>
      <SEO title="404: Not Found" />
      <StandardPadding>
        <Title variant="h1" mb={3}>
          NOT FOUND
        </Title>
        <Paragraph mb={6}>
          You just hit a route that doesn&#39;t exist.
        </Paragraph>
        <Button link={{ to: "/" }} variant="filled">
          Home
        </Button>
      </StandardPadding>
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query NotFoundPage {
    contentfulSite {
      title
      header {
        ...Header
      }
      footer {
        ...Footer
      }
    }
  }
`
